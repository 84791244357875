import Alert from '@mui/material/Alert';
import StNotificationBannerContainer from './NotificationBanner.style';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { getAlertStatusIcon } from '../../../lib/utils/contactForm';
import { setModalData, showModal } from '../../../features/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LinkField, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { setConfig, hideNotificationBanner } from '../../../features/configSlice';
import { RootState } from '../../../store/store';

export interface INotificationBanner {
  fields: IFields;
}
export interface IFields {
  cTALink: LinkField;
  content: Field;
  hideCloseOption: { value: boolean };
  modalCTAButtons: any;
  modalDescription: Field;
  modalTitle: Field;
  variant: { value: string };
}

export interface IModalData {
  title: string;
  description: string;
  buttons?: IButtons[];
}

export interface IButtons {
  name: string;
  url: string;
  variant: string;
}
const NotificationBanner = (props: INotificationBanner): JSX.Element => {
  const { fields } = props;
  const { content, hideCloseOption } = fields || {};
  const dispatch = useDispatch();
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state.config.isNotificationBannerOpen
  );

  /**
   * This useeffect will set the modal data. We haven't added any dependency because we just wants to execute this useeffect at load time
   */
  useEffect(() => {
    const buttons = fields?.modalCTAButtons.map((item: any) => ({
      name: item?.fields?.link?.value?.text || item?.name,
      url: item.fields.link.value.href,
      variant: item.fields.buttonVariant.value,
    }));
    dispatch(
      setModalData({
        title: fields?.modalTitle,
        description: fields?.modalDescription,
        buttons: buttons,
      })
    );
    dispatch(setConfig({ isNotificationBannerOpen: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(hideNotificationBanner({ isNotificationBannerOpen: false }));
  };

  const handleAlertClick = () => {
    dispatch(showModal());
  };

  if (!props?.fields) return <></>;

  const IconTag = getAlertStatusIcon(fields.variant.value);
  return (
    <>
      <StNotificationBannerContainer
        isNotificationOpen={isNotificationBannerOpen}
        hideActionButton={hideCloseOption.value}
        id="notification-banner"
      >
        <Alert
          onClose={handleClose}
          onClick={handleAlertClick}
          icon={false}
          className={fields.variant.value}
          sx={{ width: '100%', '& .MuiAlert-message': { textAlign: 'center', width: 'inherit' } }}
        >
          <Typography className="typo">
            <>
              <IconTag className="icon" />
              {content.value}
            </>
          </Typography>
        </Alert>
      </StNotificationBannerContainer>
    </>
  );
};

export default NotificationBanner;
