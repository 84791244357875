import {
  STCRWrapper,
  StCRGridContainerWithoutBackground,
} from '../../components/shared/Container/Container.styles';
import MyProfileHeader from './MyProfileHeader';
import { useEffect, useState } from 'react';
import MyProfileContact from './MyProfileContact';
import {
  GetStaticComponentProps,
  constants,
  useComponentProps,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ExtenedLayoutServiceContextInterface } from '../../lib/type/Site';
import useStoreUpdateWithStateAndTimeZoneList from '../../lib/hooks/useStoreUpdateWithStateAndTimeZoneList';
import { getAllStates } from '../../../src/GraphQL/queries/stateList.query';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { UIButton } from '../../components/shared/UIButton.styles';
import { getCookie } from '../../lib/utils/enrollmentUtil';
import { addOrUpdateCrMyProfile, getProfileData } from '../../../src/services/RegisterUserService';
import { useDispatch } from 'react-redux';
import { setProfileData } from '../../features/crProfileSlice';
import { hideLoader, showLoader } from 'src/features/loaderSlice';
import { EMAIL_TEMPLATES } from 'src/constants';
import { post } from '../../lib/utils/axiosFetcher';
import axios from 'axios';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../features/selectedLanguageSlice';
import { DataOrigin, updateUCPMJobRecordsForCR } from '../../lib/utils/ucpmHelper';

function removeTrailingSlash(url: string) {
  if (url.endsWith('/')) {
    return url.slice(0, -1);
  }
  return url;
}

const MyProfile = (props: any): JSX.Element => {
  const headerProps = props?.fields?.items[0];
  const hideButtonProps = props?.fields?.items[3];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [showForm, setShowForm] = useState<boolean>(true);
  const { stateList } = useComponentProps<any>(props.rendering.uid);
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const dispatch = useDispatch();
  const language = sitecoreContext?.language;
  const sendMailingListId = siteSettings?.sendMailingListId?.value;
  const siteSettingsId = sitecoreContext?.siteSettingsId;
  const myProfileData = useSelector((state: RootState) => state.crProfile.profileData);

  useStoreUpdateWithStateAndTimeZoneList(stateList, [], []);

  useEffect(() => {
    dispatch(
      selectLanguage({
        languageCode: 'en',
        displayName: 'English',
        nativeName: 'English',
        flagIcon: '/-/icon/Flags/32x32/flag_usa.png',
        countryName: 'united states',
        countryISO2: 'US',
        countryISO3: 'USA',
      })
    );
  }, []);

  const callDapApiSendEmail = async (email: string, existingEmail: string) => {
    try {
      const mailIdList = siteSettings?.sendMailingListId?.value || '';
      const emailType = EMAIL_TEMPLATES.REGISTRATION_WELCOME;
      const baseUrl = siteSettings?.baseUrl?.value || '';
      const cleanBaseUrl = removeTrailingSlash(baseUrl);
      const myProfileEndpoint = siteSettings?.myProfileItemUrl?.value?.href || '';
      const myProfileUrl = `${cleanBaseUrl}${myProfileEndpoint}`;
      const payload = {
        existingEmail,
        newEmail: email,
        mailIdList,
        emailType,
        baseUrl,
        myProfileUrl,
        siteSettingsId: sitecoreContext?.siteSettingsId as any,
        language: sitecoreContext?.language as any,
      };
      if (emailType) {
        await post('/Send/UpdateCREmail', payload);
      }
    } catch (_error) {}
  };

  const handleEdit = (showForm: boolean) => {
    setShowForm(showForm);
  };

  const updateUCPMJobRecords = async () => {
    if ((siteSettings as any)?.enableUCPMIntegration?.value === false) {
      return;
    }
    try {
      return await updateUCPMJobRecordsForCR({
        otSponsorId: siteSettings?.otSponsorId?.value || '',
        parentSponsorMDMID: siteSettings?.parentSponsorMDMEntityId?.value || '',
        protocolMDMID: siteSettings?.parentSponsorMDMEntityId?.value || '',
        sponsor: siteSettings?.sponsorName?.value || '',
        iqviaSponsorId: siteSettings?.iqviaSponsorId?.value || '',
        dataOrigin: DataOrigin.CR_MY_PROFILE,
        UCPMStudyName: siteSettings?.studyName?.value || '',
        baseUrl: siteSettings?.baseUrl?.value || '',
      });
    } catch (_e) {}
  };

  const handleMyProfileSave = async (formData: any, isVerifiedEmail: boolean) => {
    try {
      dispatch(showLoader());
      if (myProfileData?.basicDetails?.email?.toLowerCase() !== formData?.Email?.toLowerCase()) {
        const validateEmailUrl = `/api/cdp-api?url=/api/contacts/ValidateCrEmailIdOnSave&email=${formData?.Email}`;
        const response = await axios.get(validateEmailUrl);
        if (response?.data?.isEmailExist) {
          dispatch(hideLoader());
          return false;
        }
      }
      const guestId = getCookie('bx_guest_ref') || '';
      const existingEmailFromToken = myProfileData?.basicDetails?.email;
      const { data: res } = await addOrUpdateCrMyProfile(
        'CR',
        formData,
        isVerifiedEmail,
        guestId,
        language,
        sendMailingListId,
        siteSettingsId
      );
      if (res) {
        const existingToken = sessionStorage.getItem('myProfileVerificationToken');
        if (res.myProfileVerificationToken !== existingToken) {
          sessionStorage.setItem('myProfileVerificationToken', res.myProfileVerificationToken);
        }
        const fetchProfileResponse = await getProfileData();
        if (fetchProfileResponse.data) {
          //Wait for redux to get updated
          dispatch(setProfileData(fetchProfileResponse?.data));
          setTimeout(() => {
            dispatch(hideLoader());
          }, 2000);
        } else {
          dispatch(hideLoader());
        }
        callDapApiSendEmail(formData?.Email, existingEmailFromToken);
        updateUCPMJobRecords();
      }
      return '';
    } catch (e) {
      return;
    }
  };

  const scrollToSection = (e: any) => {
    e.preventDefault();
    try {
      const targetElement = document.getElementById('my-profile-header');
      if (targetElement) {
        const offsetTop = targetElement.offsetTop;
        window.scroll({
          top: offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    } catch (_error) {}
  };

  return (
    <STCRWrapper>
      <StCRGridContainerWithoutBackground sx={{ ...(!isMobile && { marginBottom: '1px' }) }}>
        <MyProfileHeader {...headerProps} handleEdit={handleEdit} showForm={showForm} />
        {showForm && <MyProfileContact {...props} handleMyProfileSave={handleMyProfileSave} />}
        {showForm && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
              marginTop: isMobile ? '0.5rem' : '1.5rem',
              paddingBottom: isMobile
                ? theme.spacing(3)
                : isTablet
                ? theme.spacing(8)
                : theme.spacing(5),
            }}
          >
            <UIButton
              aria-label={hideButtonProps.fields.ariaLabel.value}
              onClick={(e: any) => {
                setShowForm(false);
                scrollToSection(e);
              }}
            >
              <Box>{hideButtonProps?.fields?.link?.value?.text || 'Hide my profile'}</Box>
            </UIButton>
          </Box>
        )}
      </StCRGridContainerWithoutBackground>
    </STCRWrapper>
  );
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  //TODO: Need to check if we can have this as commom code, can be used for other contact forms
  const context = layoutData?.sitecore?.context as ExtenedLayoutServiceContextInterface;
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';
  const countryId = context?.siteSettings?.country?.id as string;

  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const stateList = await getAllStates(language, countryId);

  return {
    stateList,
  };
};

export default MyProfile;
