import * as React from 'react';
import { useRouter } from 'next/router';
import { ImageField, NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import IconButton from '@mui/material/IconButton';
import { getQuery, getQueryParams } from '../../../lib/utils/common';
import { getCookie } from 'cookies-next';
import { JOURNEY_STATUS } from '../../../../src/constants';
import CRModal from '../../CR/CRModal';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { clearRegistrationData } from '../../../features/registrationSlice';
import { useDispatch } from 'react-redux';

type Props = {
  imageField: ImageField;
  isMobile?: boolean;
  id?: string;
  ariaLabel: string;
};
const dimentions = {
  desktop: { height: 50, width: 200 },
  mobile: { height: 44, width: 180 },
};

const getmModalCTAButtonByName = (buttons: any, name: string): any => {
  if (buttons?.length > 0) {
    const item = buttons.find((item: any) => item?.name === name);
    if (item) {
      return {
        buttonVariant: item?.fields?.buttonVariant?.value,
        value: item?.fields?.link?.value?.text,
        anchor: item?.fields?.link,
        name: item?.displayName,
      };
    } else {
      return null;
    }
  }
};

const Logo = ({ id, imageField, isMobile = false, ariaLabel }: Props): JSX.Element => {
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { desktop, mobile } = dimentions;
  const { height, width } = isMobile ? mobile : desktop;
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const exitPopup: any = sitecoreContext?.route?.fields?.exitPopup;
  const modalTitle = exitPopup?.fields?.modalTitle;
  const modalDescription = exitPopup?.fields?.modalDescription;
  const modalCTAButtons = exitPopup?.fields?.modalCTAButtons;
  const cancel = getmModalCTAButtonByName(modalCTAButtons, 'Cancel');
  const continueBtn = getmModalCTAButtonByName(modalCTAButtons, 'Leave');

  const handleOnClick = () => {
    if (exitPopup) {
      setOpenModal(true);
      return false;
    }
    const isHomePage = router.asPath === '/' || router.asPath === `/${router.locale}`;
    if (isHomePage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    const removeQueryParamList = siteSettings?.RemoveQueryParamList?.value?.split('&');

    const { query, locale, defaultLocale } = router;

    if (removeQueryParamList && removeQueryParamList.length > 0) {
      for (const param of removeQueryParamList) {
        delete query[param];
      }
    }

    if (getCookie('journeyStatus') === JOURNEY_STATUS.COMPLETED) {
      const queryData = getQueryParams(query);
      let hrefValue: string;
      if (
        queryData === '' ||
        (sitecoreContext?.site?.name !== undefined &&
          sitecoreContext?.site?.name === 'ClinicalResearch')
      ) {
        hrefValue = locale === defaultLocale ? `/` : `/${locale}`;
      } else {
        hrefValue = locale === defaultLocale ? `/?${queryData}` : `/${locale}?${queryData}`;
      }
      window.location.href = hrefValue;
    } else {
      if (sitecoreContext?.site?.name === 'ClinicalResearch') {
        router.push(
          {
            pathname: '/',
            query: getQuery(query),
          },
          '/',
          {
            locale: 'en',
          }
        );
      } else {
        router.push(
          {
            pathname: '/',
            query: getQuery(query),
          },
          '/'
        );
      }
    }
    return;
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onCancelModal = () => {
    setOpenModal(false);
  };

  const onContinueModal = () => {
    const { query } = router;
    dispatch(clearRegistrationData());

    router.push({
      pathname: '/',
      query: getQuery(query),
    });
  };

  return (
    <>
      <IconButton
        data-testid="logo-click"
        onClick={handleOnClick}
        sx={{
          borderRadius: '0',
          paddingInline: '0',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
        aria-label={ariaLabel}
      >
        <NextImage id={id} field={imageField} height={height} width={width} />
      </IconButton>

      <CRModal
        open={openModal}
        handleClose={onCloseModal}
        handleCancel={onCancelModal}
        handleContinue={onContinueModal}
        title={modalTitle?.value}
        confirmButton={continueBtn}
        cancelButton={cancel}
      >
        <Typography sx={{ color: theme?.palette?.neutral8?.main }}>
          {modalDescription?.value}
        </Typography>
      </CRModal>
    </>
  );
};

export default React.memo(Logo);
