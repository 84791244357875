import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import * as React from 'react';
import ReactPlayer from 'react-player';

interface VideoProps {
  fields: {
    fields: {
      videoUrl?: Field<string>;
      isAutoplay?: Field<boolean>;
      enableMute?: Field<boolean>;
      mobileHeight?: Field<string>;
      tabletHeight?: Field<string>;
      desktopHeight?: Field<string>;
    };
  };
}

const Video = (props: VideoProps): JSX.Element => {
  const [hydrated, setHydrated] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    // This forces a rerender, so the date is rendered
    // the second time but not the first
    setHydrated(true);
  }, []);

  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null as any;
  }
  if (!props?.fields) return <></>;
  const isAutoplay = props?.fields?.fields?.isAutoplay?.value || false;

  return (
    <ReactPlayer
      data-testid="video-player"
      url={props?.fields?.fields?.videoUrl?.value}
      playing={isAutoplay}
      muted={props?.fields?.fields?.enableMute?.value}
      controls={true}
      height={'inherit'}
      width="100%"
      onError={() => {
        //console.log('Video.tsx onError', error, data);
      }}
      onReady={() => {
        //console.log('Video.tsx onReady');
      }}
      onBuffer={() => {
        //console.log('Video.tsx onBuffer');
      }}
      minHeight={
        isMobile
          ? props?.fields?.fields?.mobileHeight?.value || '270px'
          : isTablet
          ? props?.fields?.fields?.tabletHeight?.value || '330px'
          : props?.fields?.fields?.desktopHeight?.value || '400px'
      }
    />
  );
};

export default Video;
