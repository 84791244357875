import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StNoSites, StTextField } from './StudyLocation.style';
import { Field, TextField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { getStudyLocation } from './helper';
import {
  setLoaderForStudyLocaton,
  setStudyLocations,
  updateSearchZipcode,
} from '../../features/studyLocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  DEFAULT_ZIPCODE_ERROR_MESSAGE,
  DEFAULT_ZIPCODE_INVALID_MESSAGE,
  DEFAULT_ZIPCODE_REQUIRED_MESSAGE,
} from '../../constants';

interface ISearchZipcode {
  showZipcodeOnLeftPannl?: boolean;
  searchButtonText?: TextField;
  subtitle?: TextField;
  errorMessage?: Field<string>;
  placeholderText?: Field<string>;
  requiredMessage?: Field<string>;
  invalidMessage?: Field<string>;
  showEnrollSoonSites?: Field<boolean>;
  zipCodeRegex?: Field<string>;
}

const SeachZipcode: React.FC<ISearchZipcode> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  const showZipcodeOnLeftPannl = props?.showZipcodeOnLeftPannl;
  const searchButtonText = props?.searchButtonText || { value: 'Search' };
  const subtitle = props?.subtitle;
  const placeholderText = props?.placeholderText;
  const requiredMessage = props?.requiredMessage || DEFAULT_ZIPCODE_REQUIRED_MESSAGE;
  const errorMessage = props?.errorMessage || DEFAULT_ZIPCODE_ERROR_MESSAGE;
  const invalidMessage = props?.invalidMessage || DEFAULT_ZIPCODE_INVALID_MESSAGE;
  const isLoading = useSelector((state: RootState) => state.studyLocation.isLoading);

  const {
    control,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const studyLists = useSelector((state: RootState) => state.studyLocation);
  const { searchZipcode } = studyLists;
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();

  const handleStudySearch = handleSubmit(async () => {
    if (errors?.zipcode) {
      setError('zipcode', { type: 'manual', message: errorMessage?.value });
    } else {
      clearErrors('zipcode'); // will clear both errors from test.firstName and test.lastName
      // No errors, proceed with the search.
      dispatch(setLoaderForStudyLocaton(true));
      const value = getValues();
      dispatch(updateSearchZipcode(value?.zipcode));

      const queryParams = {
        SiteRootPath: sitecoreContext?.siteRootPath,
        SiteSettingsId: sitecoreContext?.siteSettingsId,
        Language: sitecoreContext?.language,
        IsSiteDetailsForHomePage: true,
        ZipCode: value?.zipcode,
      };
      try {
        const data = await getStudyLocation(queryParams);
        const filterData = props?.showEnrollSoonSites?.value
          ? data
          : data.filter((item: any) => !item.showEnrollSoon);
        dispatch(setStudyLocations(filterData));
        dispatch(setLoaderForStudyLocaton(false));
      } catch (error) {
        dispatch(setLoaderForStudyLocaton(false));
      }
    }
  });

  if (showZipcodeOnLeftPannl) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ maxHeight: '550px', height: '550px' }}
      >
        <StNoSites isMobile={isMobile} isTablet={isTablet}>
          {subtitle?.value}
        </StNoSites>

        <Stack spacing={2}>
          <Controller
            rules={{
              required: {
                value: true,
                message: requiredMessage?.value,
              },
              pattern: {
                value: new RegExp(props?.zipCodeRegex?.value || '^d{5}$'),
                message: invalidMessage?.value,
              },
            }}
            control={control}
            name={'zipcode'}
            render={({ field }) => (
              <>
                <StTextField
                  sx={{ width: '300px' }}
                  placeholder={placeholderText?.value}
                  inputProps={{
                    pattern: new RegExp(props?.zipCodeRegex?.value || '^d{5}$'),
                  }}
                  error={Boolean(errors?.zipcode)} // Set the error prop based on the presence of an error
                  {...field}
                />
                {errors.zipcode && (
                  <Stack style={{ marginTop: '0px' }} alignItems="center" direction="row">
                    <Typography color="error">{`${errors?.zipcode?.message}`}</Typography>
                  </Stack>
                )}
              </>
            )}
          />
          <Button
            onClick={handleStudySearch}
            aria-label={searchButtonText.value}
            sx={{
              width: '122px',
              maxWidth: '122px',
              marginLeft: 'auto !important',
              marginRight: 'auto !important',
              borderRadius: '50px',
            }}
            className="searchBtn"
            variant="contained"
            disabled={isLoading}
          >
            Search
          </Button>
        </Stack>
      </Grid>
    );
  }

  return (
    <form>
      <Stack spacing={2}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          <Controller
            defaultValue={searchZipcode}
            rules={{
              required: {
                value: true,
                message: requiredMessage?.value,
              },
              pattern: {
                value: new RegExp(props?.zipCodeRegex?.value || '^d{5}$'),
                message: invalidMessage?.value,
              },
            }}
            control={control}
            name="zipcode"
            render={({ field }) => (
              <>
                <StTextField
                  placeholder={placeholderText?.value}
                  inputProps={{
                    pattern: props?.zipCodeRegex?.value,
                  }}
                  error={Boolean(errors?.zipcode)} // Set the error prop based on the presence of an error
                  {...field}
                  {...(!isDesktop && { minWidth: '240px' })}
                  {...(isDesktop && { width: '240px' })}
                />
                {isMobile && errors.zipcode && (
                  <Stack
                    className="error-message"
                    style={{ marginTop: '0px' }}
                    alignItems="center"
                    direction="row"
                  >
                    <Typography color="error">{`${errors?.zipcode?.message}`}</Typography>
                  </Stack>
                )}
              </>
            )}
          />

          <Button
            onClick={handleStudySearch}
            aria-label={searchButtonText.value}
            className="searchBtn"
            type="submit"
            variant="contained"
            sx={{
              minWidth: isMobile ? '100%' : '122px',
              height: '40px',
              borderRadius: '50px',
            }}
            disabled={isLoading}
          >
            {searchButtonText?.value}
          </Button>
        </Stack>

        {isMobile !== true && errors?.zipcode && (
          <Stack
            className="error-message"
            style={{ marginTop: '0px' }}
            alignItems="center"
            direction="row"
          >
            <Typography color="error">{`${errors?.zipcode?.message}`}</Typography>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

export default SeachZipcode;
