import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { Field, RichTextField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import StudyList from '../../StudyList/StudyList';
import StudyMap from '../../StudyMap/StudyMap';
import ToggleViewPanel from '../../shared/ToggleViewPanel/ToggleViewPanel';
import React from 'react';
import { IProtocolDropdownProps } from '../ProtocolDropdown';

interface IVariationTwo extends IProtocolDropdownProps {
  variation: string | undefined;
  buttonText: TextField;
  notEnrollingMapIcon: TextField;
  enrollingMapIcon: TextField;
  noSitesAvailableText: TextField;
  siteInfoDisplayFormatHover: RichTextField;
  siteInfoDisplayFormat: RichTextField;
  siteInfoDisplayFormatClick: RichTextField;
  siteDisplayFormatListView: RichTextField;
  listText: Field<string>;
  mapText: Field<string>;
  searchButtonText: Field<string>;
  subtitle: Field<string>;
  placeholderText: Field<string>;
  showEnrollSoonSites: Field<boolean>;
  enrollSoonIcon: Field<string>;
  enrollSoonText: Field<string>;
  enrollSoonHovertext: Field<string>;
  zipCodeRegex?: Field<string>;
}

const VariationTwo: React.FC<IVariationTwo> = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const listText = props?.listText;
  const mapText = props?.mapText;
  const showEnrollSoonSites = props?.showEnrollSoonSites;
  const enrollSoonIcon = props?.enrollSoonIcon;
  const notEnrollingMapIcon = props?.notEnrollingMapIcon;
  const enrollSoonText = props?.enrollSoonText;
  const enrollSoonHovertext = props?.enrollSoonHovertext;
  const siteInfoDisplayFormat = props?.siteInfoDisplayFormat;
  return (
    <Grid
      item
      className="variation-two"
      style={
        isDesktop
          ? {
              width: '100%',
              maxWidth: '1110px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }
          : isMobile
          ? {
              width: '100%',
            }
          : isTablet
          ? {
              minHeight: '550px',
              width: '100%',
            }
          : {}
      }
    >
      <ToggleViewPanel
        option1={listText}
        option2={mapText}
        option1Component={
          <StudyList
            variation={props?.variation}
            buttonText={props?.buttonText}
            siteAddressFormat={props?.siteDisplayFormatListView}
            noSitesAvailableText={props?.noSitesAvailableText}
            searchButtonText={props?.searchButtonText}
            subtitle={props?.subtitle}
            placeholderText={props?.placeholderText}
            enrollSoonText={enrollSoonText}
            enrollSoonHovertext={enrollSoonHovertext}
            // props for filter dropdown
            enableViewAllSites={props.enableViewAllSites}
            makeDropdownReadOnly={props.makeDropdownReadOnly}
            viewAllSitesText={props.viewAllSitesText}
            viewSitesForLabelText={props.viewSitesForLabelText}
            positionDropDownInsideList={props.positionDropDownInsideList}
            showEnrollSoonSites={showEnrollSoonSites}
            zipCodeRegex={props?.zipCodeRegex}
          />
        }
        option2Component={
          <StudyMap
            variation={props.variation}
            buttonText={props?.buttonText}
            enrollingMapIcon={props?.enrollingMapIcon}
            notEnrollingMapIcon={notEnrollingMapIcon}
            enrollSoonIcon={enrollSoonIcon}
            siteInfoDisplayFormatHover={props.siteInfoDisplayFormatHover}
            siteInfoDisplayFormat={siteInfoDisplayFormat}
            enrollSoonText={enrollSoonText}
            enrollSoonHovertext={enrollSoonHovertext}
            noSitesAvailableText={props?.noSitesAvailableText}
          />
        }
        isStudyLocation={true}
        background="none"
      />
    </Grid>
  );
};

export default VariationTwo;
