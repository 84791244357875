import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SiteLocationRichText from '../SiteLocationRichText/SiteLocationRichText';
import {
  Text,
  TextField,
  RichTextField,
  ImageField,
  Image,
  useSitecoreContext,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { UIButton } from '../shared/UIButton.styles';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { StCardText, StCardTitleText } from '../SitesSelection/SiteSelection.styles';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '../SiteInfoCard/PhoneIcon';
import { selectStudyLocation } from '../../features/studyLocationSlice';
import React from 'react';

export interface ISiteInfo {
  siteCode?: string | number;
  siteName?: string;
  siteAddress?: string;
  siteCity?: string;
  siteState?: string | null;
  siteCountry?: string;
  siteZipCode?: string;
  distance?: string | number;
  isSelected?: string;
  contactPhoneNumber?: string;
  latitude: number;
  longitude: number;
  [key: string]: any; // Index signature for additional properties
  isHybridSite: boolean;
}
interface ISiteInfoCardProps {
  siteInfo: ISiteInfo;
  buttonText: TextField;
  siteAddressFormat: RichTextField;
  isShowSelectedColor?: boolean;
  cardButtonAriaLabel?: string;
  mapIcon: ImageField;
  virtualIcon: ImageField;
  virtualMapIcon: ImageField;
  virutalSiteText: TextField;
  isSelected: boolean;
  isInsideMap: boolean;
  enrollSoonText?: Field<string>;
  enrollSoonHovertext?: Field<string>;
  isHover?: boolean;
}

const StudyInfoCard: React.FC<any> = (props: ISiteInfoCardProps) => {
  const theme = useTheme();
  const siteName = props.siteInfo?.siteName || '';
  const phoneNumber = props.siteInfo?.contactPhoneNumber || '';
  const siteDistance = props.siteInfo?.distance ?? '';
  const isHybrid = props.siteInfo.isHybridSite || false;
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const distanceMetricText = siteSettings?.distanceMetric?.fields?.label?.value || 'mi';
  const isSelected = props.isSelected;
  const buttonText = props.buttonText;
  const siteAddressFormat = props.siteAddressFormat;
  const borderColor = isSelected ? theme.palette.primary.main : theme.palette.neutral4.main;
  const isInsideMap = props.isInsideMap || false;
  const showEnrollSoon = props?.siteInfo?.showEnrollSoon;
  const dispatch = useDispatch();
  const handleOnSelect = (props: any) => {
    dispatch(selectStudyLocation(props?.siteInfo));
  };
  const handleOnClick = (props: any) => {
    const telNumber = props?.siteInfo?.contactPhoneNumber;
    window.location.href = `tel:${telNumber}`;
  };

  const renderDistance = () => {
    if (!showEnrollSoon) {
      return (
        <Box display="flex" columnGap="2rem">
          {!showEnrollSoon && phoneNumber && phoneNumber !== '' && (
            <Box display="flex" columnGap="5px" sx={{ alignItems: 'center' }}>
              <PhoneIcon
                sx={{
                  color: (theme) => theme.palette.themeColor.main,
                  height: '17px',
                  width: '17px',
                }}
              />
              <StCardText>{phoneNumber}</StCardText>
            </Box>
          )}
          {(siteDistance === null || siteDistance === '' || siteDistance === undefined) &&
          isHybrid ? (
            <Box display="flex" columnGap="5px" sx={{ alignItems: 'center' }}>
              <Image field={props?.virtualIcon} />
              <StCardText>{props?.virutalSiteText?.value}</StCardText>
            </Box>
          ) : (
            <Box display="flex" columnGap="5px" sx={{ alignItems: 'center' }}>
              <PlaceIcon
                sx={{
                  color: (theme) => theme.palette.themeColor.main,
                  height: '17px',
                  width: '17px',
                }}
              />
              <StCardText>
                {siteDistance} {distanceMetricText}
              </StCardText>
            </Box>
          )}
        </Box>
      );
    }
  };
  return (
    <Card
      tabIndex={0}
      sx={{
        borderRadius: '8px',
        border: isSelected && !isInsideMap ? `2px solid ${borderColor}` : '',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        height: '100%',
      }}
      onClick={() => handleOnSelect(props)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleOnSelect(props);
        }
      }}
    >
      <CardContent
        sx={{
          padding: '16px',
        }}
      >
        <StCardTitleText gutterBottom> {siteName}</StCardTitleText>
        <Divider />
        <SiteLocationRichText
          style={{
            margin: 0,
            fontFamily: 'Proxima Nova',
            fontSize: '0.9rem',
            fontWeight: 400,
            color: theme.palette.black.main,
          }}
          siteAddressFormat={siteAddressFormat}
          siteInfo={props.siteInfo}
        />
        {renderDistance()}
        {!showEnrollSoon && (
          <UIButton
            className="btn"
            aria-label={props?.cardButtonAriaLabel}
            tabIndex={0}
            variant={isSelected ? 'contained' : 'outlined'}
            sx={{
              mt: '1rem',
              p: '4px',
              width: '100%',
              minWidth: '100px',
            }}
            onClick={() => handleOnClick(props)}
          >
            <Text field={buttonText}></Text>
          </UIButton>
        )}
      </CardContent>
    </Card>
  );
};

export default StudyInfoCard;
