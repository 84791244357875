import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { StNoSites } from '../StudyLocation/StudyLocation.style';
import StudyInfoCard from '../StudyInfoCard/StudyInfoCard';
import SeachZipcode from '../StudyLocation/SeachZipcode';
import studyLocationFilter from '../../lib/utils/studyLocationFilter';
import ProtocolDropdown, { IProtocolDropdownProps } from '../StudyLocation/ProtocolDropdown';
import { STUDY_LOCATION_VARIATIONS } from '../../constants';

interface IStudyListProps extends IProtocolDropdownProps {
  [key: string]: any;
}

const StudyList: React.FC<IStudyListProps> = (props) => {
  const theme = useTheme();
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const locations = useSelector((state: RootState) => state.studyLocation.locations);
  const selectedLocation = useSelector((state: RootState) => state.studyLocation.selectedLocation);
  const searchZipcode = useSelector((state: RootState) => state.studyLocation.searchZipcode);
  const selectedOptionValue = useSelector(
    (state: RootState) => state.studySwitcher.selectedOptionValue
  );
  const isConditionSwitcher = useSelector(
    (state: RootState) => state.studySwitcher.isConditionSwitcher
  );
  const { variation, noSitesAvailableText } = props;
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));

  const isShowFilterInsideList = props.positionDropDownInsideList && isDesktop;
  const enrollSoonText = props?.enrollSoonText;
  const siteAddressFormat = props?.siteAddressFormat;

  const renderNoSiteAvailableOrZipcode = () => {
    // This will render if there is no sites available
    if (
      locationsList.length === 0 &&
      isDesktop &&
      variation === STUDY_LOCATION_VARIATIONS.VARIATION_THREE &&
      searchZipcode === null
    ) {
      return (
        <SeachZipcode
          showZipcodeOnLeftPannl={true}
          subtitle={props?.subtitle}
          placeholderText={props.placeholderText}
          showEnrollSoonSites={props?.showEnrollSoonSites}
          zipCodeRegex={props?.zipCodeRegex}
        />
      );
    } else if (locationsList.length === 0) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingTop={'0px'}
          paddingBottom={
            isDesktop && variation !== STUDY_LOCATION_VARIATIONS.VARIATION_THREE ? '0px' : '130px'
          }
          {...(variation !== STUDY_LOCATION_VARIATIONS.VARIATION_ONE && { padding: '250px 0 0' })}
        >
          <StNoSites isMobile={isMobile} isTablet={isTablet}>
            {noSitesAvailableText?.value}
          </StNoSites>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    const newLocations = studyLocationFilter(locations, selectedOptionValue, isConditionSwitcher);
    const filterSites = newLocations.filter((study: any) => !study?.notYetEnroll);
    setLocationsList(filterSites);
  }, [locations, selectedOptionValue, isConditionSwitcher]);

  return (
    <Box
      id="sites-list"
      sx={{
        height: '550px',
        maxHeight: '550px',
        ...(isTablet && { paddingLeft: '3%', paddingRight: '3%' }),
        ...(isDesktop && { padding: '13px 5px 13px 12px' }),
        ...(isMobile && { paddingLeft: '7%', paddingRight: '7%' }),
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          overflowY:
            locationsList.length === 0
              ? 'none'
              : `${isDesktop || isMobile || isTablet ? 'scroll' : 'unset'}`,
          maxHeight: '532px',
          padding: isDesktop ? '0 7px 0 0' : 0,
        }}
      >
        {isDesktop &&
          variation === 'Variation3' &&
          (searchZipcode !== null || locations.length > 0) && (
            <Grid style={{ maxWidth: '300px', margin: '15px' }}>
              <SeachZipcode
                searchButtonText={props?.searchButtonText}
                placeholderText={props.placeholderText}
                showEnrollSoonSites={props?.showEnrollSoonSites}
                zipCodeRegex={props?.zipCodeRegex}
              />
            </Grid>
          )}
        {isShowFilterInsideList && (
          <ProtocolDropdown
            enableViewAllSites={props.enableViewAllSites}
            makeDropdownReadOnly={props.makeDropdownReadOnly}
            viewAllSitesText={props.viewAllSitesText}
            viewSitesForLabelText={props.viewSitesForLabelText}
            positionDropDownInsideList={props.positionDropDownInsideList}
            style={{
              margin: '6px 5px 5px 8px !important',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              rowGap: '2px',
              backgroundColor: theme.palette.neutral2.main,
              '#protocol-switcher-dropdown': {
                backgroundColor: theme.palette.white.main,
              },
            }}
          />
        )}

        {locationsList.length > 0 && (
          <>
            {locationsList.map((study: any, index: any) => (
              <Grid
                item
                key={index}
                sx={{ width: '100%', padding: '5px' }}
                xs={isDesktop || isMobile ? 12 : 6}
              >
                <StudyInfoCard
                  siteInfo={study}
                  buttonText={props.buttonText}
                  isSelected={selectedLocation?.siteCode === study?.siteCode}
                  enrollSoonText={enrollSoonText}
                  siteAddressFormat={study?.showEnrollSoon ? enrollSoonText : siteAddressFormat}
                  isHover={false}
                />
              </Grid>
            ))}
          </>
        )}

        {renderNoSiteAvailableOrZipcode()}
      </Grid>
    </Box>
  );
};

export default StudyList;
